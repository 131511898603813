@font-face {
	font-family: 'KarbonBold';
	src: url('../fonts/karbon-bold-webfont.eot');
	src: url('../fonts/karbon-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/karbon-bold-webfont.woff') format('woff'),
		url('../fonts/KarbonApp-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KarbonLight';
	src: url('../fonts/karbon-light-webfont.eot');
	src: url('../fonts/karbon-light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/karbon-light-webfont.woff') format('woff'),
		url('../fonts/KarbonApp-Light.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KarbonMedium';
	src: url('../fonts/karbon-medium-webfont.eot');
	src: url('../fonts/karbon-medium-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/karbon-medium-webfont.woff') format('woff'),
		url('../fonts/KarbonApp-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KarbonRegular';
	src: url('../fonts/karbon-regular-webfont.eot');
	src: url('../fonts/karbon-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/karbon-regular-webfont.woff') format('woff'),
		url('../fonts/KarbonApp-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KarbonSemibold';
	src: url('../fonts/karbon-semibold-webfont.eot');
	src: url('../fonts/karbon-semibold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/karbon-semibold-webfont.woff') format('woff'),
		url('../fonts/KarbonApp-Semibold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'KarbonThin';
	src: url('../fonts/karbon-thin-webfont.eot');
	src: url('../fonts/karbon-thin-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/karbon-thin-webfont.woff') format('woff'),
		url('../fonts/KarbonApp-Thin.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'nexi';
	src: url('../fonts/nexi.eot');
	src: url('../fonts/nexi.eot?#iefix') format('embedded-opentype'), url('../fonts/nexi.woff') format('woff'),
		url('../fonts/nexi.ttf') format('truetype'), url('../fonts/nexi.svg#nexi') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FontAwesome4';
	src: url('../fonts/fa/fa-v4compatibility.ttf');
	// src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format("embedded-opentype"), url('../fonts/fontawesome-webfont.woff2') format("woff2"), url('../fonts/fontawesome-webfont.woff') format("woff"), url('../fonts/fontawesome-webfont.ttf') format("truetype"), url('../fonts/fontawesome-webfont.svg#fontawesomeregular') format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FontAwesome';
	src: url('../fonts/fa/fa-brands-400.ttf');
	// src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format("embedded-opentype"), url('../fonts/fontawesome-webfont.woff2') format("woff2"), url('../fonts/fontawesome-webfont.woff') format("woff"), url('../fonts/fontawesome-webfont.ttf') format("truetype"), url('../fonts/fontawesome-webfont.svg#fontawesomeregular') format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon]:before {
	font-family: 'nexi' !important;
	content: attr('data-icon');
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
	font-family: 'nexi' !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
	content: 'b';
}

.icon-card:before {
	content: 'c';
}

.icon-world:before {
	content: 'd';
}

.icon-security:before {
	content: 'e';
}

.icon-graph:before {
	content: 'f';
}

.icon-eye:before {
	content: 'g';
}

.icon-connected:before {
	content: 'h';
}

.icon-device:before {
	content: 'i';
}

.icon-close:before {
	content: 'j';
}

.icon-card-wave:before {
	content: 'a';
}

.icon-chevron:before {
	content: 'k';
}

.icon-arrow-right:before {
	content: 'l';
}

.icon-arrow-left:before {
	content: 'm';
}

.icon-3dsecure:before {
	content: 'n';
}

.icon-accesso-bloccato:before {
	content: 'o';
}

.icon-acquisto:before {
	content: 'p';
}

.icon-aereo:before {
	content: 'q';
}

.icon-alert:before {
	content: 'r';
}

.icon-allegato:before {
	content: 's';
}

.icon-banca:before {
	content: 't';
}

.icon-calendario:before {
	content: 'u';
}

.icon-carta:before {
	content: 'v';
}

.icon-cartaceo:before {
	content: 'w';
}

.icon-computer:before {
	content: 'x';
}

.icon-contestazione:before {
	content: 'y';
}

.icon-contrassegna:before {
	content: 'z';
}

.icon-dati-accesso:before {
	content: 'A';
}

.icon-dati-attivazione:before {
	content: 'B';
}

.icon-download:before {
	content: 'C';
}

.icon-edit:before {
	content: 'D';
}

.icon-estero:before {
	content: 'E';
}

.icon-euro:before {
	content: 'F';
}

.icon-file-pdf:before {
	content: 'G';
}

.icon-filtri:before {
	content: 'H';
}

.icon-flag:before {
	content: 'I';
}

.icon-foto:before {
	content: 'J';
}

.icon-grafico-barre:before {
	content: 'K';
}

.icon-credit-card:before {
	content: 'L';
}

.icon-criptazione:before {
	content: 'M';
}

.icon-grafico-torta:before {
	content: 'N';
}

.icon-info:before {
	content: 'O';
}

.icon-italia:before {
	content: 'P';
}

.icon-mail:before {
	content: 'Q';
}

.icon-ok:before {
	content: 'R';
}

.icon-ombrello:before {
	content: 'S';
}

.icon-omino:before {
	content: 'T';
}

.icon-online:before {
	content: 'U';
}

.icon-passato:before {
	content: 'V';
}

.icon-place:before {
	content: 'W';
}

.icon-pos:before {
	content: 'X';
}

.icon-pos-punto-vendita:before {
	content: 'Y';
}

.icon-prelievo-contante:before {
	content: 'Z';
}

.icon-sicurezza:before {
	content: '0';
}

.icon-sms:before {
	content: '1';
}

.icon-stella:before {
	content: '2';
}

.icon-telefono:before {
	content: '3';
}

.icon-ticket:before {
	content: '4';
}

.icon-valigia:before {
	content: '5';
}

.icon-vela:before {
	content: '6';
}

.icon-wireless:before {
	content: '7';
}

.icon-rateizzazione:before {
	content: '8';
}

.icon-asknexi:before {
	content: '9';
}

.icon-face-404:before {
	content: '!';
}

.icon-lock:before {
	content: "'";
}

.icon-prenotazione-traghetti:before {
	content: '#';
}

.icon-noleggio-auto:before {
	content: '$';
}

.icon-buoni-pasto:before {
	content: '%';
}

.icon-assistente-virtuale:before {
	content: '&';
}

.icon-hotel:before {
	content: "'";
}

.icon-tariffe-speciali:before {
	content: ')';
}

.icon-vacanze:before {
	content: '*';
}

.icon-volo-hotel:before {
	content: '+';
}

.icon-parcheggio:before {
	content: '(';
}

/* Icons with uppercase content character needs to be forced uppercase on IE11 */
.icon-dati-accesso:before,
.icon-dati-attivazione:before,
.icon-download:before,
.icon-edit:before,
.icon-estero:before,
.icon-euro:before,
.icon-file-pdf:before,
.icon-filtri:before,
.icon-flag:before,
.icon-foto:before,
.icon-grafico-barre:before,
.icon-credit-card:before,
.icon-criptazione:before,
.icon-grafico-torta:before,
.icon-info:before,
.icon-italia:before,
.icon-mail:before,
.icon-ok:before,
.icon-ombrello:before,
.icon-omino:before,
.icon-online:before,
.icon-passato:before,
.icon-place:before,
.icon-pos:before,
.icon-pos-punto-vendita:before,
.icon-prelievo-contante:before {
	text-transform: uppercase !important;
}

/**CSS Class for Nexi Font**/
.nexi-icon {
	font-family: nexi;
	vertical-align: middle;
}

@font-face {
	font-family: 'Frutiger';
	src: url('../fonts/Frutiger-Light.otf');
}
@font-face {
	font-family: 'Frutiger Roman';
	src: url('../fonts/Frutiger55Roman.otf');
}
@font-face {
	font-family: 'Frutiger';
	src: url('../fonts/Frutiger-Bold.otf');
	font-weight: bold;
}
@font-face {
	font-family: 'VistaSlabReg';
	src: url('../fonts/vistaslab-reg.ttf');
}
@font-face {
	font-family: 'Frutiger IE';
	src: url('../fonts/ie/Frutiger-Light.eot');
}
@font-face {
	font-family: 'Frutiger Roman IE';
	src: url('../fonts/ie/Frutiger55Roman.eot');
}
@font-face {
	font-family: 'Frutiger IE';
	src: url('../fonts/ie/Frutiger-Bold.eot');
	font-weight: bold;
}
@font-face {
	font-family: 'VistaSlabReg IE';
	src: url('../fonts/ie/vistaslab-reg.eot');
}

.KarbonSemibold {
	font-family: 'KarbonSemibold';
}
.KarbonMedium {
	font-family: 'KarbonMedium';
}
