.carousel {
	padding: 30px;
	margin: auto;

	.slideGrid {
		padding: 0 40px 30px 40px;
		width: 100%;
	}

	.category {
		position: relative;

		.image {
			background-color: rgba(0, 0, 0, 0.25);
			width: 100%;
			border-radius: 30px;
			filter: brightness(75%);

			height: 250px;
			object-fit: cover;
		}
		.name {
			position: absolute;
			bottom: 40px;
			left: 30px;
			font-family: 'KarbonMedium';
			font-size: 40px;
			color: #fff;
		}
		.arrow {
			position: absolute;
			bottom: 30px;
			right: 30px;
		}
	}

	.swiper-button-next {
	}
	.swiper-button-prev {
	}

	.swiper-pagination {
		bottom: 0;
	}

	.swiper-pagination-bullet {
		--swiper-theme-color: #000;
		opacity: 0.25;
		color: #000;
	}

	.swiper-pagination-bullet-active {
		--swiper-theme-color: #000;
		opacity: 0.75;
		color: #000;
	}
}
