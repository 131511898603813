.Card{
	background-color: #fff;
	background-color: #fff;
	border-radius: 30px;
	padding: 40px 30px;
	color: #000;
	font-family: KarbonMedium;
	cursor: pointer;

	.name{
		color: #000;
		font-size: 25px;
		font-family: KarbonMedium;
		text-align: center;
		height: 55px;
		font-weight: bold;
		line-height: 1.1;
		margin-bottom:9px;
		overflow:hidden;
	}
	.image{
		min-height: 245px;
		text-align: center;

		.loader{
			margin:auto;
		}
		img{
			min-height: auto;
			max-height: 245px;
		}
	}

	.text{
		white-space: nowrap;
		margin-top: 10px;

		.btn{
			font-family: KarbonMedium;
			color: #fff;
			background-color: #2d32aa;
			border-radius: 30px;
			float: right;
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}
}