.Home {
	.avviso {
		background-color: #daeeff;
		border-color: solid 1px #daeeff;
		border-radius: 10px;
		color: #000;

		margin: -20px 0 20px 0;
		padding: 20px;

		.titolo_avviso {
			font-weight: bold;
			width: 100%;
			text-align: center;
		}
		.testo_avviso {
			width: 100%;
			text-align: center;
		}
	}
	.Header {
		width: 100%;
		height: 500px;
		position: relative;
		background-image: url('../../images/header.jpg');
		background-position-x: 100%;
		background-position-y: 0%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		&.charity {
			background-image: url('../../images/categories/full/charity.jpg');
		}
		&.ricette {
			background-image: url('../../images/categories/full/ricette.jpg');
		}
		&.passioni {
			background-image: url('../../images/categories/full/passioni.jpg');
		}
		&.casa {
			background-image: url('../../images/categories/full/casa.jpg');
		}
		&.shopping {
			background-image: url('../../images/categories/full/shopping.jpg');
		}
		&.sport {
			background-image: url('../../images/categories/full/sport.jpg');
		}
		&.tech {
			background-image: url('../../images/categories/full/tech.jpg');
		}
		&.viaggi {
			background-image: url('../../images/categories/full/viaggi.jpg');
		}
		&.benessere {
			background-image: url('../../images/categories/full/benessere.jpg');
		}
		&.outlet {
			background-image: url('../../images/categories/full/outlet.jpg');
		}
		&.edizione {
			background-image: url('../../images/categories/full/offerte_speciali.jpg');
			// background-image: url('../../images/categories/full/offerte_speciali.christmas2023.jpg');
			// background-position: right center;
		}

		.shadow {
			background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1;
		}

		.container {
			width: 90%;
			max-width: 1440px;
			position: absolute;
			top: 40%;
			color: #ffffff;
			left: 0;
			right: 0;
			z-index: 2;
			margin: auto;
			font-size: 30px;

			font-size: 40px;
			font-family: 'KarbonMedium';
			font-weight: bold;
			text-align: center;

			.global-search-container {
				border-radius: 30px;
				display: block;
				margin: auto;
				margin-bottom: 40px;
				width: 100%;
				max-width: 600px;
				position: relative;

				#global_search_bar {
					border-radius: 30px;
					display: block;
					margin: auto;
					margin-bottom: 40px;
					width: 100%;
					padding-left: 20px;
					padding-right: 40px;
					border: none;
				}

				#global-search-btn {
					top: -3px;
					right: 0;
					position: absolute;
					display: block;
					right: 10px;
					bottom: 10px;
					background: #ffffff;
					color: #000;
					font-size: 45px;
					line-height: 60px;
					background: transparent;
					background-position: 0 center;
					background-size: 24px;
					background-repeat: no-repeat;
					border-radius: 0;
					border: 0;
					padding-right: 0;
				}
			}

			.title {
				font-size: 60px;
				font-weight: bold;
				line-height: 100%;
			}
			.content {
				font-size: 90%;
				font-family: 'KarbonMedium';
				font-weight: normal !important;
				text-align: center;
				margin-bottom: 40px;
				line-height: 100%;
			}
		}
	}

	.results {
		padding: 30px 0px;
		@media (min-width: 1024px) {
			padding: 30px 70px;
		}

		.filter {
			margin: 0 0 50px 0;

			input,
			select {
				width: 100%;
				background-color: #fff;
				border-radius: 15px;
				border: none;

				color: #444;
				line-height: 28px;
				text-align: center;
				text-transform: uppercase;
				font-size: 14px;
				letter-spacing: 1px;

				display: block;
				padding-left: 8px;
				padding-right: 20px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-family: KarbonRegular;

				padding-left: 15px !important;
				padding-right: 30px !important;
				line-height: 2.77778rem !important;
				font-size: 1.11111rem !important;

				color: #444;
				line-height: 28px;
				text-align: center;
				text-transform: uppercase;
				font-size: 14px;
				letter-spacing: 1px;
			}
			select {
				height: 52px;
			}
			input {
				text-align: left;
				color: #000;
				text-transform: none;
			}
			.slider {
				margin-right: 30px;
				.MuiGrid-root,
				.MuiSlider-rail,
				.MuiSlider-track,
				.MuiSlider-markLabelActive {
					color: #878787 !important;
				}

				.MuiSlider-thumb {
					color: #fff;
					border: solid 1px #696969;
				}
			}

			.search {
				position: relative;

				#global-search-btn {
					top: -3px;
					right: 15px;
					position: absolute;
					display: block;
					background: #ffffff;
					color: #000;
					width: 24px;
					height: 24px;
					line-height: 60px;
					background: transparent;
					background-position: 0 center;
					background-size: 24px;
					background-repeat: no-repeat;
					border-radius: 0;
					border: 0;
					padding-right: 0;
				}
			}
		}

		.Pagination {
			margin-top: 50px;
			li {
				vertical-align: bottom;

				&.ellipsis {
					display: inline-block;
					text-align: center;
					width: 40px;
					height: 40px;
					line-height: 40px;
					background: #ffffff;
					margin: 5px;
					border-radius: 10px;
					cursor: not-allowed;
				}

				button {
					cursor: pointer;
					display: block;
					line-height: 40px;
					padding: 0;
					margin: 0 1px 0 0;
					font-size: 19px;
					font-weight: bold;
					color: #706f6f;
					background: #ffffff;
					border: 0;
					border-right: 0px solid #9d9d9c;
					font-family: 'KarbonSemibold';
					width: 40px;
					height: 40px;
					border-radius: 10px;
					margin: 5px;

					&.active {
						color: #23339d;
					}

					&.previous {
						font-size: 0;
						background-image: url('../../images/arrow-left.svg');
						background-color: transparent;
						background-size: 32%;
						background-position: center;
						background-repeat: no-repeat;
					}

					&.next {
						font-size: 0;
						background-image: url('../../images/arrow-right.svg');
						background-color: transparent;
						background-size: 32%;
						background-position: center;
						background-repeat: no-repeat;
					}
				}
			}
		}
	}
}
