.Product {
	.Header {
		width: 100%;
		height: 90px;

		background: linear-gradient(45deg, #2d32aa 0%, #196fc1 100%);

		// .shadow{
		// 	background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
		// 	position: absolute;
		// 	top: 0;
		// 	bottom: 0;
		// 	left: 0;
		// 	right: 0;
		// 	z-index: 1;
		// }
	}

	.avviso {
		background-color: #daeeff;
		border-color: solid 1px #daeeff;
		border-radius: 10px;
		color: #000;

		margin: 20px 70px;
		padding: 20px;

		.titolo_avviso {
			font-weight: bold;
			width: 100%;
			text-align: center;
		}
		.testo_avviso {
			width: 100%;
			text-align: center;
		}
	}

	.productDescription {
		background-color: #fff;
		padding-bottom: 1px;

		.closeContainer {
			width: 90%;
			text-align: right;
			margin: auto;

			img {
				width: 32px;
				margin: 30px;
				cursor: pointer;
			}
		}

		.dataContainer {
			width: 90%;
			margin: 15px auto 50px auto;

			.left {
				text-align: center;
				display: inline-block;

				.name {
					width: 100%;
					font-family: KarbonMedium;
					font-size: 30px;
					text-align: center;
					font-weight: bold;
					.brand {
						text-transform: capitalize;
					}
				}

				.image {
					width: 100%;
					margin-top: 36px;
				}
			}

			.right {
				padding: 0 30px;
				.points {
					.base {
						text-align: left;
						display: inline-block;
						vertical-align: top;

						font-size: 150%;
						font-weight: bold;
						font-family: KarbonMedium;
						border-bottom: 2px solid #2d33aa;
						margin-right: 40px;

						.small {
							font-family: KarbonRegular;
							font-size: 25px;
							font-weight: 100;
							margin-left: 10px;
						}
					}

					.alternatives {
						display: inline-block;
						font-family: KarbonRegular;
						color: #c1c1c1;
						line-height: 30px;
						font-size: 25px;
						font-weight: normal;
						max-width: 50%;
						vertical-align: top;

						.alternative {
							display: inline-block;

							.bold {
								font-family: KarbonMedium;
							}
						}
					}
				}

				.description {
					.title {
						font-size: 18px;
						font-weight: 500;
						letter-spacing: 1.2px;
						line-height: 40px;
						width: 100%;
						display: block;
						margin-top: 20px;
						font-weight: bold;
						font-family: KarbonMedium;
						color: #2d33aa;
					}

					font-size: 16px;
					margin-bottom: 10px;
					font-family: KarbonMedium;
				}

				.request {
					background-color: #2d32aa;
					line-height: 1em;
					min-width: 180px;
					min-height: 40px;
					margin: 10px 0;
					padding: 11px 20px;
					border: 1px solid #2d32aa;
					text-align: center;
					letter-spacing: 2px;
					text-decoration: none;
					text-transform: uppercase;
					box-shadow: 0 0 0 0 transparent;
					transition: all 0.3s ease-out;
					font-family: 'KarbonSemibold';

					color: #fff;
					display: block;
					text-transform: uppercase;
					font-weight: 500;
					margin-top: 30px;
					font-family: 'KarbonMedium';
					max-width: 200px;
					font-size: 20px;
					border-radius: 30px;

					&:hover {
						box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.6);
					}
				}
			}
		}
	}

	.showcase {
		padding: 30px 0;
		margin: auto;
		width: 90%;

		.title {
			text-align: left;
			font-size: 30px;
			font-family: KarbonMedium;
			display: block;
			margin: auto;
			width: 100%;
			margin-bottom: 30px;
		}
	}

	.notFound {
		width: 100%;
		text-align: center;
		padding: 100px;
	}
}
