.MainLayout{
	.Topbar{
		position:fixed;
		top:0;
		left: 0;
		width:100%;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
		z-index:2;

		.inner{
			width:90%;
			padding: 30px 0;
			display:block;
			margin:auto;

			img{
				height:30px;
			}
		}
	}

	main.content{
		min-height: calc(100vh - 800px);
		background-color: #f8f8f8;
	}

	.Footer{
		.container{
			width:100%;
			padding:0;

			.sitemap__wrapper.sitemap__closed .sitemap__controller a::after{
				transform: rotate(0deg);
			}

			#sitemap{
				display:block;
				max-height:0;
				overflow: hidden;
				// transition: max-height 0.5s ease-in-out;

			}

			.footer_link_container a{
				padding-right:23px;
			}
			.legal_text{
				text-align: left;;
			}
		}

		&.sitemapopen{
			.sitemap__wrapper.sitemap__closed .sitemap__controller a::after{
				transform: rotate(180deg);
			}

			#sitemap{
				max-height:500px;
			}
		}

		.help-select-container{
			select{
				width:100%;
				background-color: transparent;
				border: none;
				font-family: KarbonRegular;
				font-size: 20px;
				text-align: center;
				text-transform: uppercase;
				color: #00b8de;
				transition: all 0.25s ease-out;
			}
		}
	}
}
