@import '_fonts.scss';
@import '_legacy.scss';
@import '_variables.scss';

@import 'layouts/main.scss';

@import 'views/home.scss';
@import 'views/product.scss';

@import 'components/card.scss';
@import 'components/carousel.scss';
@import 'components/footer.scss';

@import 'swiper.scss';

html {
	font-size: 16px !important;
}

body {
	font-family: 'KarbonRegular';
}

.Pagination {
	// overflow: hidden;
}

.MuiGrid-spacing-xs-3 {
	width: inherit !important;
	margin: inherit !important;
}

.searchicon {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
